import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MQTTContext } from '../components/MQTTConn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

const Header = () => {
  const mqttData = useContext(MQTTContext);
  
  const powerMain = mqttData.powerData?.AC_Power_Main;

  return (
    <div className="header-container">
      <header className="app-header">
        <div className="logo-container">
          <Link to="/">
            <img src="/images/LOGO-METRICA_white.png" alt="Metrica Logo" className="metrica-logo" />
          </Link>
        </div>
        <div className="site-title-container">
          <div className="site-title">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" />
            SAPELI Group, a.s. - areál PÁVOVSKÁ
          </div>
        </div>
      </header>

      {/* Oranžový bar */}
      <div className="orange-bar">
        <div className="orange-info">
          <div className="info-box">
            Aktuální osvit: {mqttData.powerData?.Sunshine !== undefined ? `${mqttData.powerData.Sunshine} W/m²` : 'Načítám...'}
          </div>
          <div className="info-box">
            Teplota: {mqttData.powerData?.Temperature !== undefined ? `${mqttData.powerData.Temperature} °C` : 'Načítám...'}
          </div>
          <div className="info-box">
            Aktuální datum: {mqttData?.dateData?.today?.date !== undefined ? `${mqttData.dateData.today.date}` : 'Načítám...'}
          </div>
          <div className="info-box">
            Aktuální čas: {mqttData.timeData?.time !== undefined ? `${mqttData.timeData.time}` : 'Načítám...'}
          </div>
          <div className="info-box">
            V provozu od: 1.7.2024
          </div>
        </div>
      </div>

      {/* Černý bar s hodnotami */}
      <div className="black-bar">
        <div className="black-info">
          <div className="info-box">
            {powerMain !== undefined 
              ? (powerMain < 0 
                  ? `DODÁVKA DO DS: ${powerMain} kW`
                  : `DODÁVKA Z DS: ${powerMain} kW`)
              : 'Načítám...'}
          </div>
          <div className="info-box">
            VÝKON BESS: {mqttData.powerData?.AC_Power_Total_EL2 !== undefined ? `${mqttData.powerData.AC_Power_Total_EL2} kW` : 'Načítám...'}
          </div>
          <div className="info-box">
            VLASTNÍ VÝROBA: {mqttData.powerData?.AC_Power_Own !== undefined ? `${mqttData.powerData.AC_Power_Own} kW` : 'Načítám...'}
          </div>
          <div className="info-box">
            SPOTŘEBA AREÁLU: {mqttData.powerData?.AC_Power_Consumption !== undefined ? `${mqttData.powerData.AC_Power_Consumption} kW` : 'Načítám...'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;