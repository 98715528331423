import React, { useContext, useState } from 'react';
import './FVEOverview.css';
import { MQTTContext } from './MQTTConn';
import {DrawAll, DrawError, DrawOverview, DrawTemp, SelectInverter} from './inverters/invertes.js';
import FVEOverviewCard from './fveOverviewCard/fveOverviewCard';
import Modal from './modal/Modal.js';
import PageTabs from './pageTabs/pageTabs.js';

const FVEOverview = () => {
  const mqttData = useContext(MQTTContext);
  const [activeModalIndex, setActiveModalIndex] = useState(0);
  const [activeModalTab, setActiveModalTab] = useState(0);
  const [activeTab, setActiveTab] = useState(0);


  // Kontrola, zda mqttData a INVData existují
  if (!mqttData || !mqttData.INVData) {
    return <div>Načítám data...</div>;
  }

  const INVData = mqttData.INVData;
  const EnergyGraphData = mqttData.dayEnergyData.energy;
  const DaySumEnergyData = mqttData.daySumData;
  const SumEnergyData = mqttData.energySumData;

  function InverterInfo(fve_name = undefined)
  {
    const inverterInfo = Object.entries(INVData).map(([key, value]) => {
    if (fve_name === undefined || fve_name === INVData[key].FVE_Name)
    return (
      SelectInverter(value, (invNumber) => setActiveModalIndex(invNumber))
    )});

    return inverterInfo;
  }

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="fve-overview">
      {/*<div className='fve-nav'>
        <button className={`fve-nav-tab ${activeTab===0 ? 'fve-nav-tab-active' : ''}`} onClick={() => setActiveTab(0)}>Přehled</button>
        <button className={`fve-nav-tab ${activeTab===1 ? 'fve-nav-tab-active' : ''}`} onClick={() => setActiveTab(1)}>STŘÍDAČE FVE 1 - Fronius</button>
        <button className={`fve-nav-tab ${activeTab===2 ? 'fve-nav-tab-active' : ''}`} onClick={() => setActiveTab(2)}>STŘÍDAČE FVE 2 - SolarEdge</button>
      </div>
      <div className='fve-body'>
        {activeTab===0 && 
          <>
            <FVEOverviewCard name="FVE 1 - Fronius" stats={{}} max={300} power={150} graph={exampleData} graphKey={"a"} status={2}/>
            <FVEOverviewCard name="FVE 2 - SolarEdge" stats={{}} max={400} power={380} graph={exampleData} graphKey={"a"} status={0}/>
          </>
        }
        {activeTab===1 && 
          <div className='inverter-grid'>
            {InverterInfo('FVE1')}
          </div>
        }
        {activeTab===2 && 
          <div className='inverter-grid'>
            {InverterInfo('FVE2')}
          </div>
        }
      </div>*/}
      <PageTabs tabs_name={['PŘEHLED', 'STŘÍDAČE FVE']} tabs={[(
          <>
            <FVEOverviewCard name="FVE" stats={{day: DaySumEnergyData.Total_Energy_FVE_Day, month: SumEnergyData.Total_Energy_FVE_Month, year: SumEnergyData.Total_Energy_FVE_Year, life: SumEnergyData.Total_Energy_FVE_Lifetime}} max={117} graph={EnergyGraphData} graphKey={"Energy_FVE"} FVE_Name={undefined} INV={INVData} />
          </>
        ),
        (
          <div className='inverter-grid'>
            {InverterInfo()}
          </div>
        )
      ]} />
      {activeModalIndex !== 0 && <Modal name={`Střídač č. ${activeModalIndex} - ${INVData['INV' + activeModalIndex].Device_Vendor} ${INVData['INV' + activeModalIndex].Device_Model}`} close={() => setActiveModalIndex(0)} tabs_name={['Přehled', 'Teploty', 'Chyby', 'Všechny hodnoty']} tabs={[(<DrawOverview INV={INVData['INV' + activeModalIndex]} />), (<DrawTemp INV={INVData['INV' + activeModalIndex]} />), (<DrawError INV={INVData['INV' + activeModalIndex]} />), (<DrawAll INV={INVData['INV' + activeModalIndex]} />)]} />}
      {/*activeModalIndex !== 0 && (
        <div className="inverter-modal" onClick={() => setActiveModalIndex(0)}>
          <div className="inverter-modal-content animate-modal" onClick={stopPropagation}>
            <div className="inverter-modal-header">
                <h1>Střídač č. {activeModalIndex} - {INVData['INV' + activeModalIndex].Device_Vendor} {INVData['INV' + activeModalIndex].Device_Model} </h1>
                <div className='inverter-modal-header-cross' onClick={() => setActiveModalIndex(0)}>&#x2716;</div>
            </div>
            <div className="inverter-modal-body">
                <div className="inverter-modal-nav">
                    <button className={`inverter-modal-nav-tab ${activeModalTab===0 ? 'inverter-modal-nav-tab-active' : ''}`} onClick={() => setActiveModalTab(0)}>Přehled</button>
                    <button className={`inverter-modal-nav-tab ${activeModalTab===1 ? 'inverter-modal-nav-tab-active' : ''}`} onClick={() => setActiveModalTab(1)}>Teploty</button>
                    <button className={`inverter-modal-nav-tab ${activeModalTab===2 ? 'inverter-modal-nav-tab-active' : ''}`} onClick={() => setActiveModalTab(2)}>Chyby</button>
                    <button className={`inverter-modal-nav-tab ${activeModalTab===3 ? 'inverter-modal-nav-tab-active' : ''}`} onClick={() => setActiveModalTab(3)}>Všechny hodnoty</button>
                </div>
                <div className="inverter-modal-tab-content">
                    {activeModalTab===0 && (<DrawOverview INV={INVData['INV' + activeModalIndex]} />)}
                    {activeModalTab===1 && (<DrawTemp INV={INVData['INV' + activeModalIndex]} />)}
                    {activeModalTab===2 && (<DrawError INV={INVData['INV' + activeModalIndex]} />)}
                    {activeModalTab===3 && (<DrawAll INV={INVData['INV' + activeModalIndex]} />)}
                </div>
            </div>
          </div>
        </div>
      )*/}
    </div>
  );
};

export default FVEOverview;

