// useMQTT.js

import { useEffect, useRef } from 'react';
import mqtt from 'mqtt';

const useMQTT = ({ setDownloadLink, setToast, setLoading, timeoutRef }) => {
  useEffect(() => {
    const client = mqtt.connect('wss://pav.sapeli.metrica.cz:9001', {
      clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
      username: 'mendry',
      password: 'Kraken14',
      clean: true,
      rejectUnauthorized: false,
    });

    client.on('connect', () => {
      console.log('Connected to MQTT server, subscribing to topic: reports/response');
      client.subscribe('reports/response', (err) => {
        if (err) {
          console.error('Subscription error:', err);
        }
      });
    });

    client.on('message', (topic, message) => {
      const parsedMessage = JSON.parse(message.toString());
      if (parsedMessage.downloadLink) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        setDownloadLink(parsedMessage.downloadLink);
        setToast({
          open: true,
          severity: 'info',
          message: 'Report je připraven ke stažení.',
        });
        setLoading(false);
      }
    });

    client.on('error', (err) => {
      console.error('MQTT Client Error:', err);
    });

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      client.end();
    };
  }, [setDownloadLink, setToast, setLoading, timeoutRef]);
};

export default useMQTT;
